<template>
  <div id="app">
    <GoogleLogin :params="params" :renderParams="renderParams" :onSuccess="onSuccess" :onFailure="onFailure"></GoogleLogin>
  </div>
</template>

<script>
import {GoogleLogin} from 'vue-google-login'

export default {
  name: 'App',
        data() {
            return {
                params: {
                    client_id: "189382253703-e7o6srf27hplk1jeag3iaj61cu7r54in.apps.googleusercontent.com"
                },
                renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                },
				methods: {
					onSuccess(googleUser) {
						console.log(googleUser);

						console.log(googleUser.getBasicProfile());
					}
				}
			}
        },
  components: {
	GoogleLogin
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
